


  export default {
    data() {
      return {

        form : {
          id : '',
          dd : '',
          mm : '',
          yy : '',
          tglFull : { from: '2020/07/08', to: '2020/07/17' },
          keterangan : '',
        },

        multi: true,
        disp : ['Mng','Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab','', '', 'Batal', 'Pilih'],
        date: [],
      
        list_data : [],

        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,


        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,
      }
    },
    methods: {


      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_MasterWaktuLibur + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
      },


      addData : function(number) {
        


        var datax = []

        this.date.forEach(element => {
          var tglx = element.toLocaleDateString();
          var tgl = tglx.split('/')
          datax.push({
            dd : tgl[0],
            mm : tgl[1],
            yy : tgl[2],
            tglFull : element,
            keterangan : this.form.keterangan
          })

        });



        fetch(this.$store.state.url.URL_MasterWaktuLibur + "Add", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(datax)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
            this.$store.commit("hideLoading");
        });

      },

      removeData : function(E){
        fetch(this.$store.state.url.URL_MasterWaktuLibur + "removeData", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : this.form.id})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
            this.$store.commit("hideLoading");
        });

      },
      selectData : function(data){
          this.form.id = data.id;
          this.form.dd = data.uraian;
          this.form.mm = data.startDatang;
          this.form.yy = data.finishDatang;
          this.form.tglFull = data.startPulang;
          this.form.keterangan = data.keterangan;
      },



      searchByDate(){
        var datex = this.form.tglFull

        var date = datex.split('/')

        // console.log(datex)

        // this.filterku.tahun = date[0];
        // this.filterku.bulan = parseInt(date[1]);
        // this.filterku.date = date[2];
        // this.getView()
      },


      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },

          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },

          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

          formatDate(date) {
            return date.toLocaleDateString('id-ID');

            // return date
          },


      // ====================================== PAGINATE ====================================







    },

    mounted () {
      this.getView();
    },
  }
